import {isModuleStates, ModuleConfig, ModuleName, ModuleStates} from "./types";
import {App, GlobalButtonsData, NavigationMenu, NavMenuCounterList} from "@ova-studio/react-hyper-admin";
import {RouteObject} from "react-router-dom";
import {CoreModule} from "../_core";
import {ActivityModuleConfig} from "../../modules/ActivityModule";
import {AdsModuleConfig} from "../../modules/AdsModule";
import {AnalyticsModuleConfig} from "../../modules/AnalyticsModule";
import {CommentsModuleConfig} from "../../modules/CommentsModule";
import {FeedbackModuleConfig} from "../../modules/FeedbackModule";
import {MediaModuleConfig} from "../../modules/MediaModule";
import {NoticesModuleConfig} from "../../modules/NoticesModule";
import {PagesModuleConfig} from "../../modules/PagesModule";
import {PoolModuleConfig} from "../../modules/PoolModule";
import {PostsModuleConfig} from "../../modules/PostsModule";
import {SettingsModuleConfig} from "../../modules/SettingsModule";
import {TermsModuleConfig} from "../../modules/TermsModule";
import API from "@ova-studio/api-helper";
import {makeNavigationDisabled} from "./utils";

const defaultStates : ModuleStates = {
    '_core': true,
    'activity': true,
    'ads': true,
    'amp': true,
    'analytics': true,
    'comments': true,
    'feedback': true,
    'login': true,
    'media': true,
    'notices': true,
    'pages': true,
    'pools': true,
    'posts': true,
    'terms': true,
    'settings': true,
};

export default class ModulesRegistry {

    private static _moduleStates: ModuleStates = defaultStates;
    private static _moduleInstanceStates: ModuleStates = defaultStates;

    private static _modules: Record<ModuleName, ModuleConfig> = {
        '_core': CoreModule,
        'activity': ActivityModuleConfig,
        'ads': AdsModuleConfig,
        'analytics': AnalyticsModuleConfig,
        'comments': CommentsModuleConfig,
        'feedback': FeedbackModuleConfig,
        'media': MediaModuleConfig,
        'notices': NoticesModuleConfig,
        'pages': PagesModuleConfig,
        'pools': PoolModuleConfig,
        'posts': PostsModuleConfig,
        'terms': TermsModuleConfig,
        'settings': SettingsModuleConfig,
    };

    private static _isModuleEnabled(name: ModuleName) {
        return this._moduleStates[name] ?? false;
    }

    public static isModuleEnabledOnInstance(name: ModuleName) {
        return this._moduleInstanceStates[name] ?? false;
    }

    public static enableModule(name: ModuleName) {
        this._moduleStates[name] = true;
    }

    // noinspection JSUnusedGlobalSymbols
    public static disableModule(name: ModuleName) {
        this._moduleStates[name] = false;
    }

    // noinspection JSUnusedGlobalSymbols
    public static registerModule(name: ModuleName, config: ModuleConfig) {
        this._modules[name] = config;
        this.enableModule(name);
    }

    // noinspection JSUnusedGlobalSymbols
    public static setModuleInstanceStates(states: unknown) {
        if (!isModuleStates(states)) {
            console.error('Invalid module states object', states);
            throw new Error('Invalid module states object');
        }

        this._moduleInstanceStates = { ...this._moduleInstanceStates, ...states };
    }

    private static _resolveModuleNavigation(name: ModuleName, config: ModuleConfig) {
        const navigation = Array.isArray(config.navigation) ? config.navigation : (config.navigation ? [config.navigation] : []);

        if (!this.isModuleEnabledOnInstance(name)) {
            return makeNavigationDisabled(navigation);
        }

        return navigation;
    }

    public static async getNavigation() : Promise<NavigationMenu> {

        return Object.entries(this._modules)
            .filter(([key]) => this._isModuleEnabled(key))
            .map(([name, config]) => this._resolveModuleNavigation(name, config))
            .flat()
            .sort((item1, item2) => item1.order - item2.order)
            .map(i => i.item);
    }

    public static async getGlobalButtons() : Promise<GlobalButtonsData> {
        return {
            create: {
                variant: 'primary',
                icon: 'plus',
                title: 'Створити',
                order: 99,
                items: {},
            }
        }
    }

    public static async getNavCounters(app: App) : Promise<NavMenuCounterList> {
        if (!app.auth.isAuth()) {
            return {};
        }

        return API.getData<NavMenuCounterList>('nav/counters');
    }

    public static getRoutes() : RouteObject[] {
        return Object.entries(this._modules)
            .filter(([key]) => this._isModuleEnabled(key))
            .map(([_,i]) => i.routes)
            .filter(i => !!i)
            .flat(1) as RouteObject[];
    }

    public static initModules(app: App) {
        for (const [ key, module ] of Object.entries(this._modules)) {
            if (!this._isModuleEnabled(key)) {
                continue;
            }

            module.init?.(app);
        }
    }
}
