import {ModuleNavigationConfig} from "./types";
import {isNavMenuChildren, NavMenuItem} from "@ova-studio/react-hyper-admin";

const makeNavigationItemDisabled = <T extends NavMenuItem>(item: T) : T => {
    if (isNavMenuChildren(item)) {
        return {
            ...item,
            disabled: true,
            children: item.children.map((child) => makeNavigationItemDisabled(child)),
        }
    }

    return {
        ...item,
        disabled: true,
    }
}

export const makeNavigationDisabled = (data: ModuleNavigationConfig[]) : ModuleNavigationConfig[] => {
    return data.map((item) => {
        return {
            ...item,
            item: makeNavigationItemDisabled(item.item),
        }
    });
}
